.table {
  margin-bottom: 0 !important;
}

thead th {
  cursor: pointer;
}

.th-20 {
  min-width: 20px;
  width: 20px;
}

.th-40 {
  min-width: 40px;
  width: 40px;
}

.th-60 {
  min-width: 60px;
  width: 60px;
}

.th-80 {
  min-width: 80px;
  width: 80px;
}

.th-100 {
  min-width: 100px;
  width: 100px;
}

.th-120 {
  min-width: 120px;
  width: 120px;
}

.th-140 {
  min-width: 140px;
  width: 140px;
}

.th-160 {
  min-width: 160px;
  width: 160px;
}

.th-180 {
  min-width: 180px;
  width: 180px;
}
