@use "../variables";

.custom-form-error {
  background: variables.$danger;
  border-radius: 0 0 3px 3px;
  color: variables.$white;
  font-size: 10px;
  line-height: 1;
  margin-top: -1px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.custom-form-error-border {
  border: 1px solid variables.$danger;
}

.custom-form-warning {
  background: variables.$warning;
  border-radius: 0 0 3px 3px;
  color: variables.$black;
  font-size: 10px;
  line-height: 1;
  margin-top: -1px;
  padding: 6px 10px;
  text-transform: uppercase;
}

.custom-form-warning-border {
  background: variables.$warning;
}
