.ngx-pagination .current {
  background: #321fdb !important;
  color: #ced2d8 !important;
  border-radius: 4px !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.ngx-pagination a:hover,
.ngx-pagination button:hover {
  background: #ced2d8 !important;
  color: #0e0e15 !important;
  border-radius: 4px !important;
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.ngx-pagination a:focus,
.ngx-pagination button:focus {
  outline: none;
}
