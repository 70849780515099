:root {
  --gray-50: hsl(204, 45%, 98%);
  --gray-600: hsl(218, 17%, 35%);
  --gray-700: hsl(218, 23%, 23%);
  --gray-800: HSL(220, 26%, 14%);
  --gray-900: hsl(230, 21%, 11%);
  --gap: 2rem;
  --duration: 30s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.marquee-container {
  display: grid;
  align-content: center;
  overflow: hidden;
  width: 100%;
  background-color: var(--gray-900);
}

.marquee-wrapper {
  margin: auto;
  max-width: 100vw;
}

.marquee {
  display: flex;
  gap: var(--gap);
}

.marquee-group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll-left var(--duration) linear infinite;
}

.marquee-tag-wrapper {
  text-decoration: none;
  color: var(--gray-50);
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  padding: 0.625rem 1.5rem 0.625rem 0.625rem;
  background-color: var(--gray-700);
  // border-radius: 9999px;
}

.marquee-tag-wrapper:hover {
  background-color: var(--gray-800);
}

.marquee-tag-container {
  display: flex;
  align-items: center;
}

.marquee-tag-icon {
  background-color: var(--gray-600);
  border-radius: 9999px;
  margin-right: 10px;
}

.marquee-tag {
  margin-left: 0;
}

@keyframes scroll-left {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}
@media (prefers-reduced-motion: reduce) {
  .marquee-group {
    animation-play-state: paused;
  }
}
