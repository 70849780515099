.capital-25 {
  background-color: #2eb85c50;
  color: #ccc;
  min-width: 55px;
  width: 55px;
}

.capital-33 {
  background-color: #c0392b;
  color: #ccc;
  min-width: 55px;
  width: 55px;
}

.capital-50 {
  background-color: #334dde;
  color: #ccc;
  min-width: 55px;
  width: 55px;
}

.capital-100 {
  background-color: #8e44ad;
  color: #ccc;
  min-width: 55px;
  width: 55px;
}
