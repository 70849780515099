// Variable overrides

$primary: #321fdb;
$secondary: #ced2d8;
$success: #2eb85c;
$info: #39f;
$warning: #f9b115;
$danger: #e55353;
$light: #ebedef;
$dark: #636f83;

$white: #fff;
$black: #000015;
