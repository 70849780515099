.ng-select .ng-select-container {
  background-color: #2f303a !important;
  border: 1px solid #2f303a !important;
  color: #ccc !important;
}

.ng-select.ng-select-opened > .ng-select-container {
  background: #2f303a !important;
  border-color: #2f303a;
}

.ng-select.ng-select-opened > .ng-select-container:hover {
  border-color: transparent transparent darkred !important;
}

.ng-dropdown-panel {
  background-color: #2f303a !important;
  border: 1px solid #181924 !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 6%);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #2f303a !important;
  color: #ccc !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #24252f !important;
  color: #ccc !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked:hover {
  background-color: #4638c2 !important;
  color: #ccc !important;
}
