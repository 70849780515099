.price-card {
  background-color: #2a2b36 !important;
  border-top: 1px dotted hsl(217deg, 18%, 71%) !important;
  color: #ced2d8 !important;
  padding-top: 5px !important;
}

.price-card-values {
  margin-top: 2px !important;
}

.price-card-symbol {
  color: #e1a82d !important;
}

.price-card-value {
  color: #ced2d8 !important;
}

.price-card-change {
  color: #45a164 !important;
  margin-top: 2px !important;
  padding-bottom: 3px !important;
}

.price-card-change-value {
  color: #ccc !important;
}
