.c-dark-theme a {
  color: #fff !important;
  text-decoration: none !important;
}

.c-sidebar-nav-item {
  cursor: pointer;
}

.c-subheader {
  background-color: #2a2b36 !important;
  height: 48px !important;
  margin-bottom: -1px !important;
  min-height: 18px !important;
}
