.scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
  width: 1px;
  height: 8px;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 30%);
}

.scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgb(247, 244, 244);
}
