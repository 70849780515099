.customcheck {
  cursor: pointer;
  display: block;
  font-size: 14px;
  padding-left: 22px;
  position: relative;
  user-select: none;
}

.customcheck input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  background-color: #eee;
  height: 16px;
  left: 0;
  position: absolute;
  top: 0;
  width: 16px;
}

.customcheck:hover input ~ .checkmark {
  background-color: #ccc;
}

.customcheck input:checked ~ .checkmark {
  background-color: #636f83;
}

.checkmark::after {
  content: '';
  display: none;
  position: absolute;
}

.customcheck input:checked ~ .checkmark::after {
  display: block;
}

.customcheck .checkmark::after {
  border: solid white;
  border-width: 0 3px 3px 0;
  height: 10px;
  left: 6px;
  top: 2px;
  transform: rotate(45deg);
  width: 5px;
}
